import * as React from "react";

const SafetyData = [
    {
        id: 1,
        title: `DrivosityCORE`,
        subtitle: 'Equipping Your Drivers to Deliver with Excellence',
        content: (
            <>
                We believe in empowering delivery drivers with the tools they need to stay safe and perform at their best. DrivosityCORE gives your first-party delivery drivers real-time insights into their driving habits, helping them make safer, smarter decisions every time they’re on the road.
            </>
        ),
        location: `/safety`,
        data: null,
    },
    {
        id: 1,
        title: ``,
        subtitle: ``,
        content: (
            <>
                Safety doesn’t happen by accident. It’s the result of a conscious effort and a commitment to excellence. Drivosity provides the tools necessary to strive for that excellence.
            </>
        ),
        location: `/safety`,
        data: {
            name: 'SCOTT TANIS',
            position: 'Chief Operating Officer'
        },
    },
    {
        id: 1,
        title: `DriveScore® - Gamified Performance and Safety`,
        subtitle: ``,
        content: (
            <>
                
                At the heart of DrivosityCORE is our DriveScore® system—an innovative tool that tracks critical driving behaviors identified by the NHTSA, such as speeding, hard braking, acceleration, and cornering. DriveScore® transforms these behaviors into real-time, actionable scores that drivers can continuously improve.
                By gamifying safe driving behaviors, DriveScore® creates an engaging driver experience. Friendly competition within your team fosters motivation, driving them to improve their scores while reducing accidents.
                Our Certified Drivers Program takes recognition further by celebrating top performers. Drivosity Certified Drivers show higher satisfaction and retention, helping you keep your best talent on the road and your fleet operating at its best.
                <br/><br/>
                Want to learn more about making your drivers safer while saving more on insurance? Schedule a free demonstration today.
            </>
        ),
        location: `/safety`,
        data: null
    },
    {
        id: 1,
        title: `Equipping Drivers to Overcome Common Challenges`,
        subtitle: ``,
        content: (
            <>
                Last-mile delivery is fast-paced, and drivers face challenges impacting performance and safety. Unplanned stops, speeding, and inefficient routes are just a few hurdles that can slow them down. DrivosityCORE equips your delivery drivers with the necessary tools to overcome these obstacles.
                <br/><br/>
                Through real-time feedback and tailored driver coaching, your drivers can:
                <ul class="list-disc list-inside text-[14px]">
                    <li>Self-correct dangerous behaviors like speeding or harsh braking</li>
                    <li>Stay on schedule by minimizing unnecessary stops</li>
                    <li>Follow optimized routes that reduce delivery times and fuel consumption..</li>
                </ul>
                <br/>
                With these tools, your delivery drivers will not only perform better but feel more satisfied and secure in their roles, leading to a better experience for your customers and your team.
            </>
        ),
        location: `/safety`,
        data: null
    },
    {
        id: 1,
        title: `Safer Driving = Lower Insurance`,
        subtitle: ``,
        content: (
            <>
                As traffic fatalities rise, insurance costs follow. One major accident can strain your business financially, but with proactive safety measures, you can reduce these risks. According to the National Safety Council, gamified telematics can reduce accidents by up to 85%. Drivosity goes even further, helping businesses reduce accidents by as much as 90% when properly utilizing DriveScores®
                <br/><br/>
                Empower your drivers with DrivosityCORE today to lower your insurance costs, reduce claims, and protect your bottom line.
            </>
        ),
        location: `/safety`,
        data: null
    },
    {
        id: 1,
        title: `GET STARTED TODAY`,
        subtitle: ``,
        content: (
            <>
                <div className="text-[13px]">
                    <h1 className="text-[20px]">Why DrivosityCORE?</h1>
                    <br/><br/>
                    Your first-party delivery drivers are the lifeblood of your delivery operations, and they deserve the best tools to stay safe and succeed. DrivosityCORE combines the most accurate data and gamified performance metrics to ensure your drivers are supported every step of the way.
                    <br/><br/>
                    Our unique <b>speed limit validation process</b> guarantees fair driver performance evaluation, giving your drivers confidence in their scores. With actionable analytics and proven results, DrivosityCORE helps drivers and managers create a safer, more efficient delivery operation.
                </div>
            </>
        ),
        location: `/safety`,
        data: null
    },
];


export {SafetyData};
