import * as React from "react";

const AboutUsData = [
    {
        id: 4,
        title: `ABOUT US`,
        content: (
            <>
                We go beyond delivering great drivers—we provide a comprehensive last-mile delivery platform that enhances safety, efficiency, and performance. Our real-time insights and actionable data empower drivers to improve their habits and help managers coach more effectively. By fostering a culture of safety and accountability, we help businesses thrive while creating consistently positive customer experiences.
            </>
        ),
        location: `/about-us`,
        data: null,
    },
    {
        id: 4,
        title: ``,
        content: (
            <>
                Our drive towards empowerment is the heart of this company. We strive to make it part of everything we do--the solutions we design, the culture we create with our team, and even how we plan ongoing growth as a company.
            </>
        ),
        location: `/about-us`,
        data: {
            name: 'BRIAN MORONEY',
            position: 'CEO and Founding Partner'
        },
    },
    {
        id: 4,
        title: ``,
        content: (
            <>
                <span className='font-semibold'>Drivosity</span> started out as a GPS tracking and safety solution for food delivery drivers. Today, Drivosity is the go-to GPS-based tracking system for leading food delivery brands. We have since expanded to provide tracking, safety, property protection, and fleet management solutions to businesses with last mile delivery operations.
            </>
        ),
        location: `/about-us`,
        data: null
    },
    {
        id: 4,
        title: `OUR STORY`,
        content: (
            <>
                <div className='mb-7'>
                    Drivosity initially started as a solution to a problem: counteracting the rising costs of food delivery operations in an increasingly expensive insurance market. An industry with tight margins, safety was historically cost prohibitive in last mile delivery. We believe that safe and productive drivers should be in all communities and businesses of the world, thus Drivosity was born.
                </div>
                <div className='mb-7'>
                    Our team leverages decades of combined experience in technology and delivery to provide solutions that manage safety while bettering customer service times, operational transparency, driver engagement, as well as team productivity. By investing in ourselves and our team, we have been able to grow to be the leading safety and productivity solution for the largest global players in last mile delivery.
                </div>
                <div className='mb-7'>
                    Drivosity’s culture of success through empowerment, both with our customers and with team members, has fostered extraordinary growth and an exciting, vibrant, globally-geared work environment where everyone makes a difference and saves lives.
                </div>
            </>
        ),
        location: `/about-us`,
        data: null
    },
    {
        id: 4,
        title: `MEET SOME OF THE TEAM`,
        content: (
            <>
                Our international team is made up of a diverse group of individuals with one common passion: making a safer and more productive world. Coming from different backgrounds spanning from restaurant operations, to non-profits, to software engineering, our different perspectives and experiences bring together a company that is built to change the delivery world.
            </>
        ),
        location: `/about-us`,
        data: null
    },
    {
        id: 4,
        title: `Work with us. We value diversity at Drivosity`,
        content: (
            <>
                All qualified applicants will receive consideration for employment without regard to race, age, sex, color, religion, sexual orientation, gender identity, national origin, protected veteran status, or on the basis of disability.
            </>
        ),
        location: `/about-us`,
        data: null
    },
    {
        id: 4,
        title: ``,
        content: `
            ONE TEAM. GLOBAL PRESENCE,
            US HEADQUARTERS,
            NORTH MACEDONIA OFFICE,
            SERBIA OFFICE
        `,
        location: `/about-us`,
        data: null
    },
];

export {AboutUsData};
